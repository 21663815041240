import React from "react";
import logo from "../images/logo_dark.svg";
import footerfacebook from "../images/footer-facebook-icon.png";
import x from "../images/1690643640twitter-x-icon-png.png";
import instagram from "../images/1658587303instagram-png.png";
import linkedin from "../images/1656996409linkedin-symbol.png";
import dicord from "../images/1691730813discord-icon-png.png";
import medium from "../images/1679302724Medium-icon.png";
import telegram from "../images/1683044996telegram-logo-png.png";

function Footer() {
  return (
    <>
      <footer id="contactus" className="bg-white">
        <div className="container">      
          <div className="row">
            <div className="col-lg-3">
              <img src={logo} alt="" className="footerLogo" />
              <div className="socialIcon flex-wrap mt-3">
                <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/ecliptoncom">
                  <img alt="facebook" src={footerfacebook} />
                </a>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/eclipton_com">
                    <img alt="x" src={x} />
                  </a>
                </div>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/eclipton_com/">
                    <img alt="instagram" src={instagram} />
                  </a>
                </div>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/eclipton/">
                    <img alt="linkedin" src={linkedin} />
                  </a>
                </div>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://t.me/ecliptonchat">
                    <img alt="telegram" src={telegram} />
                  </a>
                </div>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://discord.com/invite/Yhtgp7nQ">
                    <img alt="discord" src={dicord} />
                  </a>
                </div>
                <div>
                  <a rel="noopener noreferrer" target="_blank" href="https://eclipton.medium.com/" className="mediumLink">
                    <img alt="medium" src={medium} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row">
                {/* <div className="col-md-12">
                  <h4>India</h4>
                </div> */}
                <div className="col-md-5">
                  <h5>Salem</h5>
                  {/* <p>Eclipton Technologies Private Limited</p> */}
                  <p>533, 535, II Floor, Trichy Main Road, Gugai</p>
                  <p>Near Gandhi Statue</p>
                  <p>Salem - 636006</p>
                  <p>Tamil Nadu, India</p>
                </div>
                <div className="col-md-7 mt-3 mt-md-0">
                  <h5>Bangalore</h5>
                  <p>A1901, A Block, 19th Floor, Miraya Rose,</p>
                  <p>HAL Old Airport Rd, Whitefield,</p>
                  <p>Bangalore - 560066</p>
                  <p>Karnataka, India.</p>
                </div>
                <div className="col-md-12 mt-3">
                  <h5>Europe</h5>
                  <p>Blockoville OÜ</p>
                  <p>Registry Code: 14625867, </p>
                  <p>Roosikrantsi tn 2-681 Tallinn Harju County 10119</p>
                  <p>Estonia</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row mt-4 mt-lg-0">
                <div className="col-lg-6 col-md-4">
                  {/* <h4 className="pb-1">Our Services</h4> */}
                  <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/home">Social Wall</a>
                  <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/blogs/all">Blog</a>
                  <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/login">Passionomy</a>
                  {/* <a href="#">Marketplace</a> */}
                  <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/login">Exchange</a>
                  <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/login">Trade</a>
                </div>
                <div className="col-lg-6 col-md-4">
                  {/* <h4 className="pb-1">Our Product</h4> */}
                  <a rel="noopener noreferrer" target="_blank" href="https://ecliptonpay.com/">Eclipton Pay</a>
                  {/* <a rel="noopener noreferrer" target="_blank" href="https://social.eclipton.com/login">Eclipton</a> */}
                  <div className="">
                    {/* <h4 className="pb-1">Policies</h4> */}
                    <a rel="noopener noreferrer" target="_blank" href="https://eclipton.com/pages/AML-policy.html">
                      AML Policy
                    </a>
                    <a rel="noopener noreferrer" target="_blank" href="https://eclipton.com/pages/risk-disclosure.html">
                      Risk Disclosure
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <h4 className="pb-1">Get Our Newsletter</h4>
              <div className="content">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your Email here..."
                  />
                  <span className="input-group-btn">
                    <button className="primaryBtn" type="submit">
                      Send
                    </button>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="footerDetaile bg-gray-100">
              <p>Eclipton Technologies is partnered with Blockoville OÜ to operate the Eclipton.com. Blockoville OÜ has its registered address at Roosikrantsi tn 2-681 Tallinn Harju County 10119, Estonia and is governed by Estonian law.  </p>
              </div>
            </div>
          </div>
          <div className="copyRight">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">                
                <div className="d-flex copyRight-text">
                  <a rel="noopener noreferrer" target="_blank" href="https://eclipton.com/pages/terms-and-conditions.html">
                    Terms & Conditions
                  </a>
                  <span className="px-2">|</span>
                  <a rel="noopener noreferrer" target="_blank" href="https://eclipton.com/pages/privacy-and-policy.html">
                    Privacy Policy
                  </a>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <p className="">&copy; Eclipton 2024</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
