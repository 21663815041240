import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Container,Nav, Navbar, NavDropdown} from 'react-bootstrap';
import logo from '../images/logo_dark.svg';
import './style.css';
import pitchPdf from '../docs/Eclipton_Pitch_Deck.pdf';

function Navigation() {
  // Navbar transition animation while scrolling
  const [navSize, setnavSize] = useState("6rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#fff") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("6rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  // Scroll to the top of the page when location changes
  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0); 
  // }, [location]);

  return (   
    <>
    <Navbar expand="lg"  fixed="top" style={{
      backgroundColor: navColor,
      height: navSize,
      transition: "all 1s"
    }}>
      <Container>
        <Navbar.Brand href="/" className="ml-auto"><img alt="Logo" src={logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto d-flex align-items-md-center">
            <NavLink className="nav-link me-3" to="/">Home</NavLink>
            <a className="nav-link me-3" href="https://social.eclipton.com/login">Trade</a>
            <a className="nav-link me-3" href="#pressSec">Press</a>
            {/* <NavLink className="nav-link me-3" to="/token">Token</NavLink>     */}
            {/* <NavDropdown title="Docs" align="end" id="docsNavDrop" className="me-3">
              <NavDropdown.Item rel="noopener noreferrer" target="_blank" href="https://eclipton.gitbook.io/eclipton-whitepaper/overview/our-mission-and-vision">Whitepaper</NavDropdown.Item>
              <NavDropdown.Item rel="noopener noreferrer" target="_blank" href={pitchPdf}>Pitch Deck</NavDropdown.Item>
            </NavDropdown>       
            <NavLink className="nav-link me-3" to="/pressSec">Press</NavLink>    */}  
            <a className="navBtn d-inline-block px-4" href="https://social.eclipton.com/login">Login</a>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>  
    <a href="https://social.eclipton.com/login" className="expertSec text-uppercase"><span>Trade</span></a>
    </> 
  );
}

export default Navigation;
